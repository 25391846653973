<template>
	<ul class="list-none p-0 m-0 flex align-items-center font-medium mb-3">
		<li>
			<a class="text-500 no-underline line-height-3 cursor-pointer"
				>Bitacoras</a
			>
		</li>
		<li class="px-2">
			<i class="pi pi-angle-right text-500 line-height-3"></i>
		</li>
		<li>
			<span class="text-900 line-height-3">Listado Bit&aacute;coras</span>
		</li>
	</ul>
	<div class="surface-section px-4 py-5 md:px-6 lg:px-8">
		<div
			class="flex align-items-start flex-column lg:justify-content-between lg:flex-row"
		>
			<div>
				<div class="font-medium text-3xl text-900">Administrar Bit&aacute;coras</div>
				<div class="flex align-items-center text-700 flex-wrap">
					<div class="mr-5 flex align-items-center mt-0">
						<i class="pi pi-briefcase mr-2"></i>
						<span>{{ totalRegistros }} Bit&aacute;coras</span>
					</div>
				</div>
			</div>
			<div class="flex mt-3 lg:mt-0">
				<Button
					label="Exportar"
					class="p-button-outlined mr-2"
					icon="pi pi-file-excel"
					v-tooltip.top="'Exportar a Excel'"
					@click="exportarExcel"
				>
				</Button>
			</div>
		</div>
		<div class="mt-4">
			<DataTable
				ref="dtbitacoras"
				dataKey="id"
				:value="bitacoras"
				:paginator="true"
				:loading="cargando"
				:rows="10"
				:rowHover="true"
				class="p-datatable-sm"
				paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
				:rowsPerPageOptions="[5, 10, 25, 50, 100]"
				currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords} Bitacoras"
				responsiveLayout="scroll"
			>
				<template #loading>
					<div class="flex align-items-center justify-content-center">
						<ProgressSpinner />
					</div>
				</template>

				<template #empty> No existen Registros</template>

				<Column field="id" header="CÓDIGO" :sortable="true"></Column>
				<Column field="nombre_usuario" header="USUARIO"></Column>
				<Column field="accion" header="ACCION"></Column>
				<Column field="tabla" header="TABLA"></Column>
				<Column field="fecha" header="FECHA REGISTRO"></Column>
			</DataTable>
		</div>
	</div>
</template>
<script>
import BitacoraService from "@/service/BitacoraService";

export default {
	data() {
		return {
			bitacoras: [],
			cargando: false,
			totalRegistros: 0,
			exportarExcel: false,
		};
	},
	bitacoraService: null,

	created() {
		this.bitacoraService = new BitacoraService();
	},

	mounted() {
		this.bitacoraService.getBitacorasAll().then((data) => {
			this.bitacoras = data;
			this.cargando = false;
		});
	},

	watch: {
		bitacoras() {
			this.totalRegistros = this.bitacoras.length;
		},
	},
};
</script>
